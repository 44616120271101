import React from "react";
import { Link, graphql } from "gatsby";

import { MDXRenderer } from "gatsby-plugin-mdx";
import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm, scale } from "../utils/typography";

// hljs.configure({useBR: true});

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  }
  componentDidUpdate() {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
  }

  render() {
    const post = this.props.data.mdx;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next, slug } = this.props.pageContext;
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.excerpt}
          slug={slug}
        />
        <div className={["row", "pb-0", "pl-3", "pr-3"].join(" ")}>
          <h1>{post.frontmatter.title}</h1>
        </div>
        <div
          className={["row", "pl-3", "pb-5", "text-left"].join(" ")}
          style={{
            ...scale(-1 / 5)
            // display: `block`,
            // marginBottom: rhythm(1),
            // marginTop: rhythm(-1),
          }}
        >
          {post.frontmatter.date}
        </div>
        {/* <div dangerouslySetInnerHTML={{ __html: post.body }} /> */}
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr
          style={{
            marginBottom: rhythm(1)
          }}
        />
        <Bio />

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0
          }}
        >
          <li>
            {previous && (
              <Link to={`/posts${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/posts${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
    allFile(filter: { ext: { regex: "/(json|csv)/gm" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;
